import { MouseEvent, useCallback } from 'react';
import { UseModalReturnProps } from './props';
import { useSearchParams } from '../../useSearchParams';
import { usePathname } from '../../usePathname';
import { NavigateFunction } from '@starsoft/common/interfaces/navigate-function';
import { useNavigate } from '../../useNavigate';
import { useHash } from '../../useHash';

export function useModal(modalKey: string): UseModalReturnProps {
  const search: URLSearchParams = useSearchParams();
  const pathname: string = usePathname();
  const hash: string = useHash();
  const isOpen: boolean = hash?.includes(`#${modalKey}`);
  const navigate: NavigateFunction = useNavigate();

  const onClose = useCallback(
    (clearParams: boolean = true) => {
      navigate(
        {
          pathname,
          search: clearParams ? undefined : search.toString(),
        },
        {
          preventScrollReset: true,
        },
      );
    },
    [pathname, search, navigate],
  );

  const setSearchParams = useCallback(
    <T,>(searchParamKey: string, value: T) => {
      search.set(searchParamKey, `${value}`);
      navigate(
        {
          search: search.toString(),
          hash: modalKey,
        },
        {
          preventScrollReset: true,
        },
      );
    },
    [search, modalKey, navigate],
  );

  function handleContainer(event: MouseEvent<HTMLDivElement>): void {
    event.stopPropagation();
  }

  return {
    isOpen,
    onClose,
    handleContainer,
    setSearchParams,
  };
}

export type { UseModalReturnProps };
